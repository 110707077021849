@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* ROOT */
#root {
}
:root {
  --main-color: #9b6f52;
  --main-color-opacity: #9b6f524b;
  --black: rgb(17, 16, 16);
  --black-opacity: rgba(17, 16, 16, 0.575);
  --white: white;
  --small-size: 13px;
  --title-size: clamp(1rem, calc(-0.875rem + 2.2vw), 3.5rem);
  --basic-text-size: clamp(1rem, calc(-0.875rem + 1.3vw), 3.5rem);
  --mini-title-size: clamp(1rem, calc(-0.875rem + 1.6vw), 3.5rem);
  --body-bg-color: #e8e8e8;
}

* {
  font-weight: normal;
}

body {
  background-color: #e8e8e8;
  background-color: var(--body-bg-color);
  font-family: "Roboto", sans-serif;
}
/* ROOT */
/* NAVBAR **/
.navBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  background-color: rgba(17, 16, 16, 0.575);
  background-color: var(--black-opacity);
  padding: 1em;
}
.navMenu {
  background-color: transparent;
  justify-content: space-evenly;
  align-items: center;
  border: none;
}

.navMenuItem:first-child {
  order: -1 !important;
}
.navMenuItem * {
  color: white !important;
  color: var(--white) !important;
  font-size: clamp(1rem, calc(-0.875rem + 1.5vw), 3.5rem) !important;
}
.ant-menu-submenu-popup .navMenuTextItem * {
  color: white !important;
  color: var(--white) !important;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  display: none !important;
}

.navMenu .ant-menu-item-active:hover {
  color: white !important;
}

.navMenuItem {
  position: relative;
}
.navMenuTextItem a::before {
  transition: 0.5s !important;
  position: absolute !important;
  top: inherit;
  bottom: 0;
  left: 0px !important;
  content: "" !important;
  width: 0 !important;
  height: 3px !important;
  border-radius: 2px !important;
  background-color: #9b6f52 !important;
  background-color: var(--main-color) !important;
}
.navMenuTextItem a:hover::before {
  width: 100% !important;
  transition: 0.5s;
}

.langSwitch {
  background-color: #9b6f52;
  background-color: var(--main-color);
  padding: 0.2em;
  height: 28px;
}
.langSwitch .ant-switch-handle {
  position: absolute;
  top: 5px;
}
.langSwitch .ant-switch-inner {
  color: white !important;
  color: var(--white) !important;

  font-size: 17px !important;
}

/************************************* NAVBAR /*************************************/
/************************************* CAROUSEL /*************************************/
.headerCarousel .slick-dots-bottom {
  bottom: 10vh;
}
.headerCarousel .slick-dots-bottom button {
  background-color: #9b6f52 !important;
  background-color: var(--main-color) !important;
  border: 2px solid rgb(17, 16, 16) !important;
  border: 2px solid var(--black) !important;
  height: 25px !important;
}

.headerCarouselInnerWrap {
  position: relative;
}
.headerCarouselInnerWrap h3,
.headerCarouselInnerWrap h4,
.headerCarouselInnerWrap h5 {
  position: absolute;
  padding: 0.5rem 3rem;
  z-index: 2;
  color: white;
  color: var(--white);
  margin: 2em 0;
  text-align: left;
}

.headerCarouselInnerWrap h3 {
  font-size: clamp(1rem, calc(-0.875rem + 2.8vw), 8vw);
  top: 15vh;
  background-color: #9b6f52;
  background-color: var(--main-color);
  opacity: 0.8;
}
.headerCarouselInnerWrap h4 {
  font-size: clamp(1rem, calc(-0.875rem + 2vw), 8vw);
  top: 35vh;
  background-color: #9b6f52;
  background-color: var(--main-color);
  opacity: 0.8;
}
.headerCarouselInnerWrap img {
  object-fit: cover;
}
.headerCarouselInnerWrap h5 {
  font-size: clamp(1rem, calc(-0.875rem + 2vw), 8vw);
  top: 50vh;
  background-color: #9b6f52;
  background-color: var(--main-color);
  opacity: 0.8;
}
/* CAROUSEL */

/* MAIN SERVICES */
.mainServiceTitle span {
  font-size: clamp(1rem, calc(-0.875rem + 2.2vw), 3.5rem);
  font-size: var(--title-size);
}
.mainServiceTitle {
  margin-top: 3em !important;
}

.mainServiceWrap {
  display: flex;
  justify-content: space-between;
  padding: 1em 0;
  flex-wrap: wrap;
}
.mainServiceItem {
  display: flex;
  margin: 1em 0;
  flex: 1 1 30%;
}
.mainServiceItemImgWrap {
  height: 70px;
  min-width: 70px;
  border-radius: 50%;
  background-color: #9b6f52;
  background-color: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainServiceItemImgWrap img {
  height: 38px;
}
.mainServiceTextWrap {
  padding: 0 2em;
}
.mainServiceTextWrap h5 {
  font-weight: bold;
  padding-bottom: 0.2em;
  text-align: left;
  font-size: clamp(1rem, calc(-0.875rem + 1.6vw), 3.5rem);
  font-size: var(--mini-title-size);
  margin: 0;
}

.mainServiceTextWrap p {
  padding: 0.5em 0;
  text-align: left;
  font-size: clamp(1rem, calc(-0.875rem + 1.3vw), 3.5rem);
  font-size: var(--basic-text-size);
}
/* MAIN SERVICES */
/* WHY US */
.WhyUsWrap {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}
.WhyUsTitle {
  margin-top: 3em !important;
}
.WhyUsTitle span {
  font-size: clamp(1rem, calc(-0.875rem + 2.2vw), 3.5rem);
  font-size: var(--title-size);
}
.WhyUsCollapse {
  width: 49%;
}
.WhyUsWrap .ant-collapse {
  background-color: transparent;
  border: none;

  border-radius: 6px;
}

.WhyUsCollapse .ant-collapse-header {
  background-color: #9b6f52;
  background-color: var(--main-color);
  font-size: clamp(1rem, calc(-0.875rem + 1.6vw), 3.5rem);
  font-size: var(--mini-title-size);
  color: white !important;
  color: var(--white) !important;
}
.WhyUsCollapse .WhyUsPanel:first-of-type .ant-collapse-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.WhyUsPanel .ant-collapse-content-box p {
  font-size: clamp(1rem, calc(-0.875rem + 1.3vw), 3.5rem);
  font-size: var(--basic-text-size);
  padding: 3em 2em;
}
.WhyUsGallery {
  width: 49%;
}
.WhyUsGallery img {
  padding: 0.5em;
}
.WhyUsGallery .ant-image-mask {
  background: #9b6f524b;
  background: var(--main-color-opacity);
}
.WhyUsGallery .ant-image-mask .ant-image-mask-info {
  font-size: clamp(1rem, calc(-0.875rem + 1.6vw), 3.5rem);
  font-size: var(--mini-title-size);
  color: white;
  color: var(--white);
  font-weight: bold;
}

/* WHY US */
/* STAT */
.statTitle::before,
.statTitle::after {
  color: white;
  color: var(--white);
}
.statTitle {
  padding: 3em 0;
  margin-top: 3em !important;
}
.statTitle span {
  font-size: clamp(1rem, calc(-0.875rem + 2.2vw), 3.5rem);
  font-size: var(--title-size);
  color: white;
  color: var(--white);
}
.statWrap h2 {
  font-size: clamp(1rem, calc(-0.875rem + 3.5vw), 8vw);
  color: white;
  font-weight: bold;
}
.statWrap h5 {
  font-size: clamp(1rem, calc(-0.875rem + 2vw), 8vw);
  color: white;
  font-weight: bold;
}
.statItemWrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.statItem {
  padding: 1em;
  margin: 1em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #9b6f52;
  background-color: var(--main-color);
  border-radius: 8px;
  flex: 0 0 30%;
}
.statItem > div {
  margin: 0.8em 0;
}

.statImg {
  height: 70px;
}
.statCounter {
  font-size: clamp(1rem, calc(-0.875rem + 2.5vw), 8vw);
  font-weight: bold;
  color: white;
  color: var(--white);
}
.statLabel {
  font-size: clamp(1rem, calc(-0.875rem + 1.6vw), 3.5rem);
  font-size: var(--mini-title-size);
  font-weight: bold;
  color: white;
  color: var(--white);
}
/* STAT */

/* PARTNERS */
.partnersTitle {
  margin-top: 3em !important;
}
.partnersTitle span {
  font-size: clamp(1rem, calc(-0.875rem + 2.2vw), 3.5rem);
  font-size: var(--title-size);
  color: rgb(17, 16, 16);
  color: var(--black);
}
.partnersWrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.partnersItem {
  flex: 1 1 33%;
  padding: 1em;
  margin: 2em 0;
}
.partners-card {
  background-color: transparent;
  height: 200px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  border-radius: 8px;
}

.partners-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: -webkit-transform ease-in-out 0.8s;
  transition: transform ease-in-out 0.8s;
  transition: transform ease-in-out 0.8s, -webkit-transform ease-in-out 0.8s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.partners-card:hover .partners-card-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.partners-card-front,
.partners-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 8px;
}

.partners-card-front {
  border: 3px solid #9b6f52;
  border: 3px solid var(--main-color);
  background-color: white;
  background-color: var(--white);
  color: rgb(17, 16, 16);
  color: var(--black);
}
.partners-card-front img {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}
.partners-card-back {
  background-color: #9b6f52;
  background-color: var(--main-color);
  color: rgb(17, 16, 16);
  color: var(--black);
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
}
.partners-card-back h4 {
  font-size: clamp(1rem, calc(-0.875rem + 1.6vw), 3.5rem);
  font-size: var(--mini-title-size);
  color: white;
  color: var(--white);
  font-weight: bold;
}
/* PARTNERS */
/* COMMENTS */
.commentsTitle span {
  font-size: clamp(1rem, calc(-0.875rem + 2.2vw), 3.5rem);
  font-size: var(--title-size);
  color: rgb(17, 16, 16);
  color: var(--black);
}
.commentsTitle {
  margin-top: 3em !important;
}

/* COMMENTS */
.commentWrap {
  background-color: #9b6f52;
  background-color: var(--main-color);
  border-radius: 8px;
  padding: 1em;
}
.commentWrap .ant-comment-inner {
  display: flex;
  padding: 16px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.commentWrap .ant-comment-inner .ant-avatar,
.commentWrap .ant-comment-inner .ant-avatar-image img {
  width: 100px;
  height: 100px;
}
.commentWrap .ant-comment-content-author {
  justify-content: center;
  padding: 1em;
}
.commentWrap .ant-comment-content-author span p {
  font-size: clamp(1rem, calc(-0.875rem + 2.2vw), 3.5rem);
  font-size: var(--title-size);
  color: white;
  color: var(--white);
  margin: 0;
}
.commentWrap .ant-comment-content-detail p {
  color: white;
  color: var(--white);
}
.commentWrap .ant-comment-inner .ant-comment-content-detail {
  padding: 0.5em 2em 1em 2em;
  font-size: clamp(1rem, calc(-0.875rem + 1.6vw), 3.5rem);
  font-size: var(--mini-title-size);
}

.commentsCarouselWrap .slick-dots-bottom {
  bottom: 22px;
}
.commentsCarouselWrap .slick-dots li {
  margin: 0 25px !important;
  width: 24px !important;
}
.commentsCarouselWrap .slick-dots li button {
  width: 25px;
  height: 25px;
  background: rgb(17, 16, 16) !important;
  background: var(--black) !important;
  border-radius: 50%;
  border: 5px double rgb(17, 16, 16);
  border: 5px double var(--black);
}
/* COMMENTS */
/* FOOTER */
.footerBackground {
  background-color: rgb(17, 16, 16);
  background-color: var(--black);
  margin-top: 3em;
}
.footer {
  display: flex;
  padding: 2em 0;
  justify-content: space-between;
}
.footerLeft {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 33%;
  padding: 2em;
}

.footerLeft h5 {
  font-size: clamp(1rem, calc(-0.875rem + 2.2vw), 3.5rem);
  font-size: var(--title-size);
  color: white;
  color: var(--white);
  text-align: left;
  border-bottom: 4px solid #9b6f52;
  border-bottom: 4px solid var(--main-color);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.footerLeft img {
  height: 100px;
  width: 100px;
}
.footerLeft p {
  font-size: clamp(1rem, calc(-0.875rem + 1.3vw), 3.5rem);
  font-size: var(--basic-text-size);
  color: white;
  color: var(--white);
  text-align: left;
}

.footerCenter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 33%;
  padding: 2em;
}

.footerCenter h5 {
  font-size: clamp(1rem, calc(-0.875rem + 2.2vw), 3.5rem);
  font-size: var(--title-size);
  color: white;
  color: var(--white);
  text-align: left;
  border-bottom: 4px solid #9b6f52;
  border-bottom: 4px solid var(--main-color);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.footerCenter ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 0;
  list-style: none;
}
.footerCenter ul li {
  margin: 0.5em 0;
  flex: 0 0 50%;
  text-align: left;
}
.footerCenter ul li a {
  color: white;
  color: var(--white);
  font-size: clamp(1rem, calc(-0.875rem + 1.3vw), 3.5rem);
  font-size: var(--basic-text-size);
  text-align: left;
  position: relative;
  transition: 0.5s;
  cursor: pointer;
}

.footerCenter ul li span {
  color: white;
  color: var(--white);
  font-size: clamp(1rem, calc(-0.875rem + 1.3vw), 3.5rem);
  font-size: var(--basic-text-size);
}
.footerCenter ul li a::after {
  transition: 0.5s;
  position: absolute;
  top: 20px;
  left: 5px;
  content: "";
  width: 0;
  height: 3px;
  border-radius: 2px;
  background-color: #9b6f52;
  background-color: var(--main-color);
}
.footerCenter ul li a:hover::after {
  width: 100%;
  transition: 0.5s;
}
.footerRight {
  width: 33%;
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.footerRight h5 {
  font-size: clamp(1rem, calc(-0.875rem + 2.2vw), 3.5rem);
  font-size: var(--title-size);
  color: white;
  color: var(--white);
  text-align: left;
  border-bottom: 4px solid #9b6f52;
  border-bottom: 4px solid var(--main-color);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.footerRight p b {
  color: white;
  color: var(--white);
  margin-right: 8px;
}
.footerRight p,
.footerRight a {
  font-size: clamp(1rem, calc(-0.875rem + 1.3vw), 3.5rem);
  font-size: var(--basic-text-size);
  color: white;
  color: var(--white);
  text-align: left;
}
.footerRight p span {
  font-size: clamp(1rem, calc(-0.875rem + 1.3vw), 3.5rem);
  font-size: var(--basic-text-size);
  color: var(--color);
  margin-right: 8px;
}
.footerRight ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  list-style: none;
}

.footerRight ul li img {
  height: 32px;
  transition: 0.3s ease-in;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  -webkit-transform: scale(1);
          transform: scale(1);
}
.footerRight ul li img:hover {
  transition: 0.3s ease-out;

  -webkit-filter: grayscale(0);

          filter: grayscale(0);
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.footerBottomDivider span {
  color: white;
}
.footerBottomDivider::after,
.footerBottomDivider::before {
  border-color: white !important;
}
/* FOOTER */
/* PORTFOLIO */
.portfolioTitle span {
  font-size: clamp(1rem, calc(-0.875rem + 2.2vw), 3.5rem);
  font-size: var(--title-size);
  color: rgb(17, 16, 16);
  color: var(--black);
}
.portfolioWrap {
  margin-top: 200px;
}
.portfolioWrap .ant-tabs-nav-wrap {
  justify-content: center;
}
.portfolioWrap .ant-tabs-tab-btn,
.portfolioWrap .ant-tabs-tab-active .ant-tabs-tab {
  color: white !important;
  color: var(--white) !important;
  font-weight: bold;
  text-shadow: none !important;
  outline: none !important;
}

.ant-tabs-tab-active {
  background-color: #8d4b1f !important;
}
.ant-tabs-tab:hover {
  background-color: #8d4b1f !important;
}
.portfolioWrap .ant-tabs-tab {
  background-color: #9b6f52;
  background-color: var(--main-color);
  padding: 1em;
  border-radius: 8px;
  outline: none !important;
}

.ant-tabs-ink-bar {
  background-color: transparent;
}
.ant-tabs-nav::before {
  border: none !important;
}

.projectWrap {
  display: flex;
  justify-content: center;
  padding: 1em 0;
  flex-wrap: wrap;
  min-height: 50vh;
}
.projectItem {
  flex: 0 0 33%;
  padding: 0.5em;
  margin: 0.5em 0;
}
.projectName {
  font-size: clamp(1rem, calc(-0.875rem + 1.6vw), 3.5rem);
  font-size: var(--mini-title-size);
  color: rgb(17, 16, 16);
  color: var(--black);
  font-weight: bold;
}

div.image-gallery-content:not(.fullscreen)
  .image-gallery-slide
  .image-gallery-image {
  max-height: calc(100vh-80px);
  height: 270px;
  object-fit: cover;
}

div.fullscreen
  > div.image-gallery-slide-wrapper.bottom
  > div
  div
  div.image-gallery-slide.center
  img {
  object-fit: contain;
}
.image-gallery-right-nav .image-gallery-svg,
.image-gallery-left-nav .image-gallery-svg {
  height: 64px;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:active {
  border: 3px solid #9b6f52;
  border: 3px solid var(--main-color);
}
.image-gallery-icon,
.image-gallery-icon:hover,
.image-gallery-icon:active,
.image-gallery-icon:focus {
  color: white;
  color: var(--white);
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  object-fit: cover;
  height: 80px;
}
.image-gallery-content:not(.fullscreen) .image-gallery-thumbnails-container {
  display: none;
}
/* PORTFOLIO */
/* ABOUT US */
.aboutUsTitle span {
  font-size: clamp(1rem, calc(-0.875rem + 2.2vw), 3.5rem);
  font-size: var(--title-size);
  color: rgb(17, 16, 16);
  color: var(--black);
}
.aboutUsWrap {
  margin-top: 200px;
}
.aboutUsText {
  padding: 1em;
  background-color: #9b6f52;
  background-color: var(--main-color);
  border-radius: 8px;
}

.aboutUsText h2 {
  color: white;
  color: var(--white);
  font-size: clamp(1rem, calc(-0.875rem + 1.3vw), 3.5rem);
  font-size: var(--basic-text-size);
}
/* ABOUT US */
/* CONTACT */
.contactTitle span {
  font-size: clamp(1rem, calc(-0.875rem + 2.2vw), 3.5rem);
  font-size: var(--title-size);
  color: rgb(17, 16, 16);
  color: var(--black);
}
.contactWrap {
  margin-top: 200px;
}

.contactInnerWrap {
  padding: 1em;
  background-color: #9b6f52;
  background-color: var(--main-color);
  border-radius: 8px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contactInnerWrap ul li {
  margin: 1em;
}

.contactInnerWrap p b {
  color: rgb(17, 16, 16);
  color: var(--black);
  margin-right: 8px;
}
.contactInnerWrap p span,
.contactInnerWrap p b {
  color: white;
  color: var(--white);
}
.contactInnerWrap p,
.contactInnerWrap a {
  font-size: clamp(1rem, calc(-0.875rem + 1.3vw), 3.5rem);
  font-size: var(--basic-text-size);
  color: white;
  color: var(--white);
  text-align: left;
}
.contactInnerWrap p span {
  font-size: clamp(1rem, calc(-0.875rem + 1.3vw), 3.5rem);
  font-size: var(--basic-text-size);
  color: white;
  color: var(--white);
  margin-right: 8px;
}
.contactInnerWrap ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  list-style: none;
}

.contactInnerWrap ul li img {
  height: 32px;
  transition: 0.3s ease-in;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  -webkit-transform: scale(1);
          transform: scale(1);
}
.contactInnerWrap ul li img:hover {
  transition: 0.3s ease-out;

  -webkit-filter: grayscale(0);

          filter: grayscale(0);
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.contactMap {
  margin: 1em 0;
  border-radius: 8px;
}

div.contactMap > div:nth-child(2) {
  border-radius: 8px;
}

/* CONTACT */

/************************************* ROOT /*************************************/
:root {
  --main-color: #9b6f52;
  --main-color-opacity: #9b6f524b;
  --black: rgb(17, 16, 16);
  --black-opacity: rgba(17, 16, 16, 0.575);
  --white: white;
  --small-size: 14px;
  --title-size: clamp(1rem, calc(-0.875rem + 2.4vw), 3.5rem);
  --basic-text-size: clamp(1rem, calc(-0.875rem + 1.5vw), 3.5rem);
  --mini-title-size: clamp(1rem, calc(-0.875rem + 1.8vw), 3.5rem);
  --body-bg-color: #e8e8e8;
  --hamburger: url(/static/media/hamburger.d4d74c77.svg);
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .ant-menu-submenu-popup {
    top: 128px !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .ant-menu-submenu-popup {
    top: 92px !important;
  }
}
@media only screen and (min-width: 100px) and (max-width: 576px) {
  .ant-menu-submenu-popup {
    top: 71px !important;
  }
}
/* BREAKPONT FOR HAMBURGER MENU */
@media only screen and (max-width: 992px) {
  .navLogoImg {
    height: 64px !important;
  }
}

/************************************* ROOT /*************************************/
/************************************* MAX 480 PX /*************************************/
@media only screen and (max-width: 480px) {
  .navItemMainPage {
    margin-right: 22px;
  }
}
/************************************* MAX 480 PX /*************************************/
/************************************* MAX 576 PX /*************************************/
@media only screen and (max-width: 576px) {
  .statItem {
    flex: 0 0 100% !important;
    margin: 1em 0;
  }
  .partnersItem {
    flex: 0 0 50%;
    padding: 0.5em;
    margin: 0.5em 0;
  }
  .footerRight {
    align-items: flex-start !important;
  }
  .navBackground {
    padding: 0.3em;
  }
  .navMenu {
    border: none;
  }
  .navMenu .ant-menu-submenu-title {
    display: flex;
  }
}
/************************************* MAX 576 PX /*************************************/
/************************************* MAX 768 PX /*************************************/
@media only screen and (max-width: 768px) {
  .mainServiceItem {
    display: flex;
    margin: 1em 0;
    flex: 1 1 100% !important;
  }
  .headerCarousel .headerCarouselimg {
    object-fit: cover;
  }
  .headerCarousel .slick-dots-bottom button {
    background-color: rgb(17, 16, 16) !important;
    background-color: var(--black) !important;

    height: 15px !important;
    width: 15px !important;
  }

  div.portfolioWrap
    > div:nth-child(2)
    > div
    > div
    > div
    > div.ant-tabs-nav
    > div.ant-tabs-nav-wrap
    > div {
    flex-direction: column !important;
  }
  div.portfolioWrap
    > div:nth-child(2)
    > div
    > div
    > div
    > div.ant-tabs-nav
    > div.ant-tabs-nav-wrap
    > div
    > div {
    margin: 0.5em 0;
  }
  .projectItem {
    flex: 0 0 100%;
  }
  .contactWrap {
    margin-top: 130px;
  }
  .aboutUsWrap {
    margin-top: 130px;
  }
}
/************************************* MAX 768 PX /*************************************/
/************************************* MAX 992 PX /*************************************/
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .ant-menu-submenu-popup {
    top: 92px !important;
  }
}
@media only screen and (max-width: 992px) {
  .navMenu .ant-menu-submenu-title span {
    height: 32px;
    width: 32px;
    background-image: url(/static/media/hamburger.d4d74c77.svg);
    background-image: var(--hamburger);
    background-repeat: no-repeat;
    background-size: contain;
  }

  .ant-menu-submenu-popup > .ant-menu {
    background-color: #9b6f52 !important;
    background-color: var(--main-color) !important;
    box-shadow: none;
  }
  .ant-menu-submenu-popup {
    background-color: #9b6f52 !important;
    background-color: var(--main-color) !important;
    right: 0 !important;
    left: inherit !important;
    width: 50%;
    height: 100vh;
  }

  .ant-menu-submenu-popup .navMenuItem {
    margin: 2em 0;
  }
  .ant-menu-submenu-popup .navMenuItem span {
    color: rgb(17, 16, 16);
    color: var(--black);
  }
  .ant-menu-submenu::after {
    display: none;
  }
  .navMenu .ant-menu-submenu-title span svg {
    display: none;
  }
  .WhyUsCollapse {
    width: 100%;
  }
  .WhyUsGallery {
    width: 100%;
  }
  .statLabel {
    font-size: 14px;
    font-size: var(--small-size);
  }
  .partners-card-back h4 {
    font-size: 14px;
    font-size: var(--small-size);
  }
  .footer {
    flex-direction: column;
    justify-content: center;
  }

  .footerRight,
  .footerCenter,
  .footerLeft {
    justify-content: center;
    align-items: center;
    width: 100% !important;
    padding: 1em 0;
  }
  .footerRight h5,
  .footerCenter h5,
  .footerLeft h5 {
    text-align: center;
    border-bottom: 1px solid #9b6f52;
    border-bottom: 1px solid var(--main-color);
  }
  .footerLeft p {
    text-align: center;
  }
  .footerCenter ul li {
    text-align: center;
  }
  .footerRight ul li {
    padding: 1em;
  }
}
/************************************* MAX 992 PX /*************************************/
/************************************* MAX 1200 PX /*************************************/
@media only screen and (max-width: 1200px) {
  .headerCarouselInnerWrap h3 {
    font-size: 4.5vw !important;
    top: 15vh;
    margin: 0 !important;
    text-align: left;
  }
  .headerCarouselInnerWrap h4 {
    font-size: 4vw !important;
    top: 35vh;
    margin: 0 !important;
    text-align: left;
  }

  .headerCarouselInnerWrap h5 {
    font-size: 3.5vw !important;
    top: 50vh;
    margin: 0 !important;
    text-align: left;
  }
  .mainServiceItem {
    display: flex;
    margin: 1em 0;
    flex: 1 1 50%;
  }
  .WhyUsGallery img {
    padding: 0.5em;
    height: 287px;
    object-fit: cover;
  }
  .WhyUsPanel .ant-collapse-content-box p {
    padding: 0;
  }
  .statItem {
    flex: 0 0 25%;
  }
  .footerRight,
  .footerCenter,
  .footerLeft {
    width: 32%;
    padding: 1em 0;
  }
  .footerLeft p {
    font-size: 14px;
    font-size: var(--small-size);
  }
  .footerCenter ul li a {
    font-size: 14px;
    font-size: var(--small-size);
  }
  .footerRight p,
  .footerRight a {
    font-size: 14px;
    font-size: var(--small-size);
  }
  .footerRight ul li img {
    height: 22px;
  }
}
/************************************* MAX 1200 PX /*************************************/

